import mutations from './mutations';
import actions from './actions';
import { StateType } from './types';

const state: StateType = {
  conversions: null,
  conversionsCache: null,
  isConversionsCached: false,
  conversionsError: null,
  isConversionsLoading: false,

  orderConversions: null,
  orderConversionsCache: null,
  isOrderConversionsCached: false,
  orderConversionsError: null,
  isOrderConversionsLoading: false,

  dailyPerformance: null,
  dailyPerformanceError: null,
  isDailyPerformanceLoading: false,
  isDailyPerformanceCached: false,
  dailyPerformanceCacheUrl: null,

  geoListPerformance: null,
  geoListPerformanceError: null,
  isGeoListPerformanceLoading: false,
  geoListCacheUrl: null,
  isGeoListCached: false,

  geoPerformance: null,
  geoPerformanceError: null,
  isGeoPerformanceLoading: false,

  conversionsBreakdown: null,
  conversionsBreakdownError: null,
  isConversionsBreakdownLoading: false,
  isConversionBreakdownCached: false,
  conversionBreakdownCacheUrl: null,

  campaignKpi: null,
  campaignKpiError: null,
  isCampaignKpiLoading: false,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

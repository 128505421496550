import Vue from 'vue';
import { Campaign } from '../../../types/filters';
import CONSTANTS from './constants';
import { CurrentFilterSelection } from './types';

export default {
  [CONSTANTS.SET_CAMPAIGNS_SELECTION](_state, campaignIDs: Array<string>): void {
    _state.selectedCampaignIDs = campaignIDs;
  },
  [CONSTANTS.SET_CAMPAIGNS_HASFILTERED](_state, status: boolean): void {
    _state.hasCampaignFiltered = status;
  },
  [CONSTANTS.SET_CAMPAIGNS_HASTYPEFILTERED](_state, status: boolean): void {
    _state.hasTypeFiltered = status;
  },
  [CONSTANTS.SET_SELECTED_CAMPAIGNS](_state, campaigns: Array<Campaign>): void {
    _state.selectedCampaigns = campaigns;
  },
  [CONSTANTS.RELOAD_CAMPAIGNS](_state, reload: boolean): void {
    _state.reloadedCampaigns = reload;
  },
  [CONSTANTS.SET_CAMPAIGNTYPE_SELECTION](_state, campaignTypes: string[]): void {
    _state.selectedCampaignTypes = campaignTypes;
  },
  [CONSTANTS.SET_FILTERS](_state, filters: CurrentFilterSelection): void {
    Vue.set(_state, 'current', filters);
  },
  [CONSTANTS.UPDATE_FILTERS](_state, filters: CurrentFilterSelection): void {
    Vue.set(_state, 'current', filters);
  },
  [CONSTANTS.UPDATE_ALL_FILTERS](_state, filters: CurrentFilterSelection): void {
    Vue.set(_state, 'current', filters);
  },
  [CONSTANTS.SET_LOADING](_state, status: boolean): void {
    _state.loading = status;
  },
  [CONSTANTS.SET_TACTIC_PAGE__VIEW](_state, view: string): void {
    _state.tacticPageView = view;
  },
};

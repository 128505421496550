import axios, { AxiosResponse } from 'axios';
import { ApolloQueryResult } from '@apollo/client/core';
import CONSTANTS from '../constants';
import requests from './../requests';
import { GetAccountLinkingResponse, UpdateAccountLinkingResponse, LinearAdminError } from '../types';
import { logError } from '../../../index';
import utils from '../../../../util';
import { session_id } from '../../../../main';

const getAgencyAdvertisers = async ({ commit }, payload): Promise<object> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.get(`/api/getAgencyAdvertisers/`, {
      params: { agency: payload.agency },
    });
    if (!req.data.properties.length) {
      return { error: 'No advertisers found' };
    }
    return req.data.properties;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    return { error: 'Error occured, please try again' };
  }
};

const moveCampaign = async ({ commit }, payload): Promise<object> => {
  try {
    commit(CONSTANTS.SET_CAMPAIGN_MOVE, true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.post(`/api/moveCampaign/`, {
      targetAdvertiserId: payload.targetAdvertiserId,
      currentAdvertiserId: payload.currentAdvertiserId,
      campaignId: payload.campaignId,
      campaignType: payload.campaignType,
    });
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_CAMPAIGN_MOVE, exp.message);
    return { error: 'Error occured, please try again' };
  } finally {
    commit(CONSTANTS.SET_CAMPAIGN_MOVE, false);
  }
};

const hideCampaign = async ({ commit }, payload): Promise<object> => {
  try {
    commit(CONSTANTS.SET_CAMPAIGN_HIDE, true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.post(`/api/hideCampaign/`, {
      advertiserId: payload.advertiserId,
      campaignId: payload.campaignId,
    });
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_CAMPAIGN_HIDE, exp.message);
    return { error: 'Error occured, please try again' };
  } finally {
    commit(CONSTANTS.SET_CAMPAIGN_HIDE, false);
  }
};

const unhideCampaign = async ({ commit }, { onSuccessCallback, advertiserId, campaignId }): Promise<object> => {
  try {
    commit(CONSTANTS.SET_CAMPAIGN_UNHIDE, true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.post(`/api/unhideCampaign/`, {
      advertiserId,
      campaignId,
    });
    if (onSuccessCallback) {
      await onSuccessCallback();
    }
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_CAMPAIGN_UNHIDE, exp.message);
    return { error: 'Error occured, please try again' };
  } finally {
    commit(CONSTANTS.SET_CAMPAIGN_UNHIDE, false);
  }
};

const getHiddenCampaigns = async ({ commit }, payload): Promise<object> => {
  try {
    commit(CONSTANTS.SET_CAMPAIGN_UNHIDE, true);
    const limit = payload.limit && payload.limit !== '' ? `?limit=${payload.limit}` : '';
    const offset = payload.offset && payload.offset !== '' ? `&offset=${payload.offset}` : '';
    const search = payload.search && payload.search !== '' ? `&search=${payload.search}` : '';
    const req = await axios.get(`/api/getHiddenCampaigns/${limit}${offset}${search}`);
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_CAMPAIGN_UNHIDE, exp.message);
    return { error: 'Error occured, please try again' };
  } finally {
    commit(CONSTANTS.SET_CAMPAIGN_UNHIDE, false);
  }
};

const updateCampaignData = async ({ commit }, payload): Promise<object> => {
  try {
    commit(CONSTANTS.SET_UPDATE_CAMPAIGN_DATA, true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.put(`/api/updateCampaignData/`, {
      advertiserId: payload.advertiserId,
      campaignId: payload.campaignId,
      data: payload.data,
    });
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_UPDATE_CAMPAIGN_DATA, exp.message);
    return { error: 'Error occured, please try again' };
  } finally {
    commit(CONSTANTS.SET_UPDATE_CAMPAIGN_DATA, false);
  }
};

const updateAccountLinking = async ({ commit }, linking): Promise<UpdateAccountLinkingResponse | LinearAdminError> => {
  try {
    commit(CONSTANTS.SET_LOADING, true);
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);

    if (typeof linking.GoogleAnalytics.ViewId !== 'string') {
      linking.GoogleAnalytics.ViewId = String(linking.GoogleAnalytics.ViewId);
    }
    const GQLPayload = utils.removeAllTypenames(linking);
    const response: ApolloQueryResult<UpdateAccountLinkingResponse> = await requests.updateAccountLinking(GQLPayload);
    if (response?.errors) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    const updatedResponse = utils.removeNullFromObjectOrArray(response.data.updateAccountLinking);
    commit(CONSTANTS.SET_ACCOUNT_LINKING, updatedResponse);
    return updatedResponse;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.updateAccountLinking');
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLinearAdminClientsSearch = async ({ commit }, payload): Promise<any> => {
  commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);
  try {
    commit(CONSTANTS.SET_LOADING, true);
    // encode name
    const limit = payload.limit && payload.limit !== '' ? `?limit=${payload.limit}` : '';
    const name = payload.name && payload.name !== '' ? `&name=${encodeURIComponent(payload.name)}` : '';
    const offset = payload.offset && payload.offset !== '' ? `&offset=${payload.offset}` : '';
    const partners =
      payload.partners && payload.partners !== '' ? `&partners=${encodeURIComponent(payload.partners)}` : '';
    const stations =
      payload.stations && payload.stations !== '' ? `&stations=${encodeURIComponent(payload.stations)}` : '';
    const sortby = payload.sortby && payload.sortby !== '' ? `&sortby=${payload.sortby}` : '';
    const sortdesc = payload.sortdesc && payload.sortdesc !== '' ? `&sortDesc=${payload.sortdesc}` : '';
    const headers = { session_id };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.get(
      `/api/linearAdminClientsSearch${limit}${name}${stations}${partners}${sortby}${sortdesc}${offset}`,
      { headers },
    );
    if (req.data.error) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, req.data.error);
      commit(CONSTANTS.SET_LOADING, false);
      return;
    }
    commit(CONSTANTS.SET_LINEAR_ADMIN_CLIENTS, req.data);
    commit(CONSTANTS.SET_LOADING, false);
    return;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    commit(CONSTANTS.SET_LOADING, false);
    throw logError(exp, 'store.linearadmin.getLinearAdminClientsSearch');
  }
};

const getAccountLinking = async ({ commit }, payload): Promise<GetAccountLinkingResponse | LinearAdminError> => {
  if (!payload.id) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, 'Missing client id');
    return;
  }
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);
    const response: ApolloQueryResult<GetAccountLinkingResponse> = await requests.getAccountLinking(payload);
    if (response?.errors) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    commit(CONSTANTS.SET_ACCOUNT_LINKING, utils.removeNullFromObjectOrArray(response.data.getAccountLinking));
    return;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.getAccountLinking');
  }
};

const uploadCustomLogo = async ({ commit }, img): Promise<void> => {
  try {
    commit(CONSTANTS.SET_LOADING, true);
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);

    const form = new FormData();
    form.append('file', img);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.post('/api/uploadCustomLogo', form);
    if (req.data && req.data.error) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, req.data.error);
      return req.data;
    }
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.uploadCustomLogo');
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

const associateOrderWithCampaign = async ({ commit }, payload): Promise<object> => {
  try {
    commit(CONSTANTS.SET_ASSOCIATE_ORDER_WITH_CAMPAIGN, true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.post(`/api/associateOrderWithCampaign`, {
      campaignType: payload.campaignType,
      advertiserId: payload.advertiserId,
      orderId: payload.orderId,
      campaignId: payload.campaignId,
      currentAdvertiserId: payload.currentAdvertiserId,
    });
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_ASSOCIATE_ORDER_WITH_CAMPAIGN, exp.message);
    return { error: 'Error occured, please try again' };
  } finally {
    commit(CONSTANTS.SET_ASSOCIATE_ORDER_WITH_CAMPAIGN, false);
  }
};

const uploadPublisherLogo = async ({ commit }, img): Promise<void> => {
  try {
    commit(CONSTANTS.SET_LOADING, true);
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);

    const form = new FormData();
    form.append('image', img);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.post('/api/uploadPublisherLogo', form);
    if (req.data && req.data.error) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, req.data.error);
      return req.data;
    }
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.uploadPublisherLogo');
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

const createPublisherLogo = async ({ commit }, payload): Promise<void> => {
  try {
    commit(CONSTANTS.SET_LOADING, true);
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.post('/api/createPublisherLogo', payload);
    if (req.data && req.data.error) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, req.data.error);
      return req.data;
    }
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.createPublisherLogo');
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

const updatePublisherLogoUrl = async ({ commit }, { logoName, newUrl }): Promise<void> => {
  try {
    commit(CONSTANTS.SET_LOADING, true);
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.put(`/api/updatePublisherLogoUrl/${logoName}`, { newUrl });
    if (req.data && req.data.error) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, req.data.error);
      return req.data;
    }
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.updatePublisherLogoUrl');
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

const removePublisherLogo = async ({ commit }, { logoName }): Promise<void> => {
  try {
    commit(CONSTANTS.SET_LOADING, true);
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.delete(`/api/removePublisherLogo/${logoName}`);
    if (req.data && req.data.error) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, req.data.error);
      return req.data;
    }
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.removePublisherLogo');
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

const getPublisherLogos = async ({ commit }, payload): Promise<void> => {
  try {
    commit(CONSTANTS.SET_LOADING, true);
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.get('/api/getPublisherLogos', payload);
    if (req.data && req.data.error) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, req.data.error);
      return req.data;
    }
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.getPublisherLogos');
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

const addPublisherLogoAlias = async ({ commit }, { logoName, alias }): Promise<void> => {
  try {
    commit(CONSTANTS.SET_LOADING, true);
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.post(`/api/addPublisherLogoAlias/${logoName}`, { alias });
    if (req.data && req.data.error) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, req.data.error);
      return req.data;
    }
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.addPublisherLogoAlias');
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

const removePublisherLogoAlias = async ({ commit }, { logoName, alias }): Promise<void> => {
  try {
    commit(CONSTANTS.SET_LOADING, true);
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.delete(`/api/removePublisherLogoAlias/${logoName}/alias/${alias}`);
    if (req.data && req.data.error) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, req.data.error);
      return req.data;
    }
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.removePublisherLogoAlias');
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

const addPublisherLogoPrefix = async ({ commit }, { logoName, prefix }): Promise<void> => {
  try {
    commit(CONSTANTS.SET_LOADING, true);
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.post(`/api/addPublisherLogoPrefix/${logoName}`, { prefix });
    if (req.data && req.data.error) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, req.data.error);
      return req.data;
    }
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.addPublisherLogoPrefix');
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

const removePublisherLogoPrefix = async ({ commit }, { logoName, prefix }): Promise<void> => {
  try {
    commit(CONSTANTS.SET_LOADING, true);
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.delete(`/api/removePublisherLogoPrefix/${logoName}/prefix/${prefix}`);
    if (req.data && req.data.error) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, req.data.error);
      return req.data;
    }
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.removePublisherLogoPrefix');
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

const moveCampaignToProduct = async ({ commit }, payload): Promise<object> => {
  try {
    commit(CONSTANTS.SET_CAMPAIGN_MOVE_TO_PRODUCT, true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.post(`/api/moveCampaignToProduct`, {
      campaignType: payload.campaignType,
      advertiserId: payload.advertiserId,
      campaignId: payload.campaignId,
      newProduct: payload.newProduct,
    });
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_CAMPAIGN_MOVE_TO_PRODUCT, exp.message);
    return { error: 'Error occured, please try again' };
  } finally {
    commit(CONSTANTS.SET_CAMPAIGN_MOVE_TO_PRODUCT, false);
  }
};

const cloneCampaignToAdvertiser = async ({ commit }, payload): Promise<object> => {
  try {
    commit(CONSTANTS.SET_ASSOCIATE_ORDER_WITH_CAMPAIGN, true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.post(`/api/cloneCampaignToAdvertiser`, {
      campaignType: payload.campaignType,
      campaignId: payload.campaignId,
      sourceAdvertiserId: payload.sourceAdvertiserId,
      targetAdvertiserId: payload.targetAdvertiserId,
      newCampaignName: payload.newCampaignName,
    });
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_CLONE_CAMPAIGN_TO_ADVERTISER, exp.message);
    return { error: 'Error occured, please try again' };
  } finally {
    commit(CONSTANTS.SET_CLONE_CAMPAIGN_TO_ADVERTISER, false);
  }
};

const updatePublisherLogoName = async ({ commit }, { logoName, newLogoName }): Promise<void> => {
  try {
    commit(CONSTANTS.SET_LOADING, true);
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.patch(`/api/updatePublisherLogoName/${logoName}`, {
      logoName: newLogoName,
    });
    if (req.data && req.data.error) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, req.data.error);
      return req.data;
    }
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.updatePublisherLogoName');
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

export default {
  getAccountLinking,
  getLinearAdminClientsSearch,
  updateAccountLinking,
  uploadCustomLogo,
  getAgencyAdvertisers,
  moveCampaign,
  updateCampaignData,
  hideCampaign,
  unhideCampaign,
  getHiddenCampaigns,
  associateOrderWithCampaign,
  uploadPublisherLogo,
  createPublisherLogo,
  updatePublisherLogoUrl,
  removePublisherLogo,
  getPublisherLogos,
  addPublisherLogoPrefix,
  addPublisherLogoAlias,
  removePublisherLogoAlias,
  removePublisherLogoPrefix,
  moveCampaignToProduct,
  cloneCampaignToAdvertiser,
  updatePublisherLogoName,
};

import Vue from 'vue';
import CONSTANTS from './constants';
import { StateType } from './types';

export default {
  // Conversions
  [CONSTANTS.SET_CONVERSIONS](state: StateType, conversions): void {
    Vue.set(state, 'conversions', conversions);
  },
  [CONSTANTS.SET_CONVERSIONS_ERROR](state: StateType, error): void {
    Vue.set(state, 'conversionsError', error);
  },
  [CONSTANTS.SET_IS_CONVERSIONS_LOADING](state: StateType, isLoading): void {
    Vue.set(state, 'isConversionsLoading', isLoading);
  },
  [CONSTANTS.SET_CONVERSIONS_CACHE](state: StateType, conversionsCache): void {
    Vue.set(state, 'conversionsCache', conversionsCache);
  },
  [CONSTANTS.SET_IS_CONVERSIONS_CACHED](state: StateType, isConversionsCached): void {
    Vue.set(state, 'isConversionsCached', isConversionsCached);
  },

  // Order Conversions
  [CONSTANTS.SET_ORDER_CONVERSIONS](state: StateType, orderConversions): void {
    Vue.set(state, 'orderConversions', orderConversions);
  },
  [CONSTANTS.SET_ORDER_CONVERSIONS_ERROR](state: StateType, error): void {
    Vue.set(state, 'orderConversionsError', error);
  },
  [CONSTANTS.SET_IS_ORDER_CONVERSIONS_LOADING](state: StateType, isLoading): void {
    Vue.set(state, 'isOrderConversionsLoading', isLoading);
  },
  [CONSTANTS.SET_ORDER_CONVERSIONS_CACHE](state: StateType, cache): void {
    Vue.set(state, 'orderConversionsCache', cache);
  },
  [CONSTANTS.SET_IS_ORDER_CONVERSIONS_CACHED](state: StateType, isCached): void {
    Vue.set(state, 'isOrderConversionsCached', isCached);
  },

  // DailyPerformance
  [CONSTANTS.SET_DAILY_PERFORMANCE](state: StateType, data): void {
    Vue.set(state, 'dailyPerformance', data);
  },
  [CONSTANTS.SET_DAILY_PERFORMANCE_ERROR](state: StateType, error): void {
    Vue.set(state, 'dailyPerformanceError', error);
  },
  [CONSTANTS.SET_IS_DAILY_PERFORMANCE_LOADING](state: StateType, isLoading): void {
    Vue.set(state, 'isDailyPerformanceLoading', isLoading);
  },
  [CONSTANTS.SET_DAILY_PERFORMANCE_CACHE_URL](state: StateType, cacheUrl): void {
    Vue.set(state, 'dailyPerformanceCacheUrl', cacheUrl);
  },
  [CONSTANTS.SET_IS_DAILY_PERFORMANCE_CACHED](state: StateType, isCached): void {
    Vue.set(state, 'isDailyPerformanceCached', isCached);
  },

  // GeoPerformance
  [CONSTANTS.SET_GEO_PERFORMANCE](state: StateType, geoPerformance): void {
    Vue.set(state, 'geoPerformance', geoPerformance);
  },
  [CONSTANTS.SET_GEO_PERFORMANCE_ERROR](state: StateType, error): void {
    Vue.set(state, 'geoPerformanceError', error);
  },
  [CONSTANTS.SET_IS_GEO_PERFORMANCE_LOADING](state: StateType, isLoading): void {
    Vue.set(state, 'isGeoPerformanceLoading', isLoading);
  },

  // GeoListPerformance
  [CONSTANTS.SET_GEO_LIST_PERFORMANCE](state: StateType, geoListPerformance): void {
    Vue.set(state, 'geoListPerformance', geoListPerformance);
  },
  [CONSTANTS.SET_GEO_LIST_PERFORMANCE_ERROR](state: StateType, error): void {
    Vue.set(state, 'geoListPerformanceError', error);
  },
  [CONSTANTS.SET_IS_GEO_LIST_PERFORMANCE_LOADING](state: StateType, isLoading): void {
    Vue.set(state, 'isGeoListPerformanceLoading', isLoading);
  },
  [CONSTANTS.SET_GEO_LIST_PERFORMANCE_CACHE_URL](state: StateType, cacheUrl): void {
    Vue.set(state, 'geoListCacheUrl', cacheUrl);
  },
  [CONSTANTS.SET_IS_GEO_LIST_PERFORMANCE_CACHED](state: StateType, isCached): void {
    Vue.set(state, 'isGeoListCached', isCached);
  },

  // Conversions Breakdown
  [CONSTANTS.SET_CONVERSIONS_BREAKDOWN](state: StateType, conversionsBreakdown): void {
    Vue.set(state, 'conversionsBreakdown', conversionsBreakdown);
  },
  [CONSTANTS.SET_CONVERSIONS_BREAKDOWN_ERROR](state: StateType, error): void {
    Vue.set(state, 'conversionsBreakdownError', error);
  },
  [CONSTANTS.SET_IS_CONVERSIONS_BREAKDOWN_LOADING](state: StateType, isLoading): void {
    Vue.set(state, 'isConversionsBreakdownLoading', isLoading);
  },
  [CONSTANTS.SET_CONVERSIONS_BREAKDOWN_CACHE_URL](state: StateType, cache): void {
    Vue.set(state, 'conversionBreakdownCacheUrl', cache);
  },
  [CONSTANTS.SET_IS_CONVERSIONS_BREAKDOWN_CACHED](state: StateType, isCached): void {
    Vue.set(state, 'isConversionBreakdownCached', isCached);
  },

  // Campaign KPI
  [CONSTANTS.SET_CAMPAIGN_KPI](state: StateType, campaignKpi): void {
    Vue.set(state, 'campaignKpi', campaignKpi);
  },
  [CONSTANTS.SET_CAMPAIGN_KPI_ERROR](state: StateType, error): void {
    Vue.set(state, 'campaignKpiError', error);
  },
  [CONSTANTS.SET_IS_CAMPAIGN_KPI_LOADING](state: StateType, isLoading): void {
    Vue.set(state, 'isCampaignKpiLoading', isLoading);
  },
};

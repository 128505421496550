import { countApiCalling } from './helpers/interceptors';
import mixpanel from './plugins/mixpanel';
import router from './router';
import store from './store/index';

export default () => {
  let isStartedCounting = false;
  const MAIN_TABS = ['orderlist', 'summary', 'home'];

  router.beforeEach((to, from, next) => {
    const firstTime = new Date().getTime();
    let secondTime;
    let performanceTime;

    // Track page view for all navigation
    mixpanel.track('$mp_web_page_view', {
      current_url_path: to.fullPath,
      current_page_title: to.meta?.title,
      previous_url_path: from.fullPath,
      distinct_id: store?.state?.customer?.user?.email || 'Shared Guest',
    });

    // First load with viewCampaign
    if (to.name === 'home' && !MAIN_TABS.includes(to.query.tab) && to.query.viewCampaigns && !isStartedCounting) {
      countApiCalling(() => {
        isStartedCounting = false;
        secondTime = new Date().getTime();
        performanceTime = secondTime - firstTime;
        mixpanel.track('Performance - first load of campaign', {
          performanceTime,
          distinct_id: store?.state?.customer?.user?.email || 'Shared Guest',
          UserName: store?.state?.customer?.user?.name || 'Shared Guest',
          url: to.fullPath,
          name: to.name,
          tab: to.query.tab,
          viewCampaigns: to.query.viewCampaigns,
          skipCache: to.query.skipCache,
        });
      });
      // Opening new tab
    } else if (to.name === 'home' && !MAIN_TABS.includes(to.query.tab) && !isStartedCounting) {
      isStartedCounting = true;
      countApiCalling(() => {
        secondTime = new Date().getTime();
        performanceTime = secondTime - firstTime;
        isStartedCounting = false;

        mixpanel.track(`Performance - new tab - ${to.query?.tab}`, {
          performanceTime,
          distinct_id: store?.state?.customer?.user?.email || 'Shared Guest',
          UserName: store?.state?.customer?.user?.name || 'Shared Guest',
          url: to.fullPath,
          name: to.name,
          tab: to.query.tab,
          viewCampaigns: to.query?.viewCampaigns,
          skipCache: to.query.skipCache,
        });
      });
    } else if (to.name !== 'home') {
      // We will implement it later if needed
      // countApiCalling(() => {
      //   secondTime = new Date().getTime();
      //   performanceTime = secondTime - firstTime;
      //   mixpanel.track(`Performance - ${to.name}`, {
      //     performanceTime,
      //     distinct_id: store?.state?.customer?.user?.email || 'Shared Guest',
      //     UserName: store?.state?.customer?.user?.name || 'Shared Guest',
      //     url: to.fullPath,
      //     name: to.name,
      //     tab: to.query?.tab,
      //     skipCache: to.query.skipCache,
      //   });
      // });
      // Open home, summary or order tab (but not main home for now)
    } else if (to.name === 'home' && MAIN_TABS.includes(to.query.tab) && to.query.tab !== 'home' && !to.query?.recent) {
      countApiCalling(() => {
        secondTime = new Date().getTime();
        performanceTime = secondTime - firstTime;

        mixpanel.track(`Performance - ${to.name} - ${to.query?.tab}`, {
          performanceTime,
          distinct_id: store?.state?.customer?.user?.email || 'Shared Guest',
          UserName: store?.state?.customer?.user?.name || 'Shared Guest',
          url: to.fullPath,
          name: to.name,
          tab: to.query.tab,
          skipCache: to.query.skipCache,
        });
      });
    } else {
      // No need to do anything
    }
    next();
  });
};

import { ApolloQueryResult } from '@apollo/client/core';
import gql from 'graphql-tag';
import { logError, apollo } from '../../..';
import CONSTANTS from '../constants';
import frags from './fragments';
import _clone from 'lodash.clonedeep';
import { Status, TacticLayoutArguments, ActivityLogArguments, AllLayoutsArguments } from './types/layoutTypes';
import { sub, lightFormat } from 'date-fns';

const getLayoutsGQL = async ({ commit }, params: TacticLayoutArguments): Promise<void> => {
  try {
    commit(CONSTANTS.SET_GQL_LOADING, true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: ApolloQueryResult<any> = await apollo().query({
      query: gql`
        ${frags.SECTION_INFO}
        ${frags.LAYOUT_INFO}
        ${frags.TABLE}
        ${frags.PROG_TABLE}
        ${frags.BAR}
        ${frags.SMRY_CTRL}
        ${frags.HM_CTRL}
        ${frags.ORDER_CTRL}
        ${frags.DMAZIP}
        ${frags.CONMAP}
        ${frags.STATION}
        ${frags.GEN_MAP}
        ${frags.GEOFENCE}
        ${frags.LINE}
        ${frags.LINEBARNEW}
        ${frags.PIE}
        ${frags.LINEBAR}
        ${frags.CAMP_OVERVIEW}
        ${frags.TOP_METRICS}
        ${frags.SIDE_SUMRY}
        ${frags.TEXT_SUMRY}
        ${frags.CAMP_STATS}
        ${frags.TARGET_LIST}
        ${frags.SI_TABLE}
        ${frags.LOGO_LIST}
        ${frags.FALLBACK}
        ${frags.TABLE_LIST}
        ${frags.SECTION_HEADER}
        ${frags.VENNDIAGRAM}
        ${frags.BENCHMARKS}

        fragment CommonLayoutFields on TacticLayout {
          ...LayoutInfo
          config {
            ...SectionInfo
            components {
              type
              components {
                ... on genericTable {
                  ...CommonPropsTable
                  columns
                  commonTweaks
                  dataChecksByCampaign
                  downloadTableData
                  exportableTableData
                  filterBy
                  footer
                  hasProgressBars
                  hideIfNoData
                  icon
                  primaryStar
                  searchEnabled
                  sortBy
                  useTacticSummaryData
                  widths
                  availableColumns
                  itemLimit
                  headers {
                    align
                    sortable
                    text
                    value
                    width
                  }
                  iconOverride {
                    Metro
                  }
                  tooltips {
                    key
                    message
                    title
                  }
                  noDataTooltips {
                    key
                    title
                    message
                  }
                  overrides {
                    ${frags.commonOverrides}
                    columns
                    itemLimit
                    hideFromUI
                  }
                  fallbackTable {
                    dataSource
                    columns
                    availableColumns
                  }
                  feedSource
                  feedSourceToExclude
                  additionalDataSource
                  additionalMetrics
                  hideFromUI
                }
                ... on genericBarChart {
                  ...CommonPropsBar
                  barCategory
                  colors
                  commonTweaks
                  dataSource
                  exportableTableData
                  hideIfNoData
                  horizontal
                  pagination
                  legendKeys
                  useTacticSummaryData
                  xAxisRotate
                  stack
                  canvasHeight
                  chartColors
                  sideTotalMetrics {
                    dataSource
                    metrics
                    useTacticSummaryData
                  }
                  tooltips {
                    key
                    message
                    title
                  }
                  overrides {
                    ${frags.commonOverrides}
                    chartColors
                  }
                  feedSource
                  feedSourceToExclude
                  additionalDataSource
                  additionalMetrics
                }
                ... on homeController {
                  ...CommonPropsHomeCtrl
                }
                ... on orderController {
                  ...CommonPropsOrderCtrl
                }
                ... on summaryController {
                  ...CommonPropsSummaryCtrl
                  exportableTableData
                  hideZeros
                  mapTweaks
                  layerLimit
                  zoomToTopNPercents
                  size {
                    height
                  }
                  overrides {
                    hideSummaryMap
                    disabledProducts
                    disabledMetrics
                    tacticTableOrder
                  }
                  hideSummaryMap
                  disabledProducts
                  disabledMetrics
                  tacticTableOrder
                }
                ... on dmaZipMap {
                  ...CommonPropsDMAZipMap
                  customStyle
                  dataChecksAny
                  hidePolygonsWithLessThan
                  legend
                  legendGradient
                  mapGradient
                  mapGradientOpacity
                  themes
                  zoomToTopNPercents
                  onlyMap
                  onlySummary
                  campaignSummary {
                    ${frags.campaignSummaryProps}
                  }
                  size {
                    height
                  }
                  overrides {
                    ${frags.commonOverrides}
                    onlyMap
                    onlySummary
                    mapShowDataFrom
                    campaignSummary {
                      topMetrics
                      bottomMetrics
                    }
                  }
                  fallbackMap {
                    name
                    dataChecks
                    dataSource
                    geoParams
                    geoNameKey
                    legend
                  }
                }
                ... on conversionMap {
                  ...CommonPropsConversionMap
                  customStyle
                  dataChecksAny
                  hidePolygonsWithLessThan
                  legend
                  legendGradient
                  mapGradient
                  mapGradientOpacity
                  themes
                  zoomToTopNPercents
                  onlyMap
                  onlySummary
                  campaignSummary {
                    ${frags.campaignSummaryProps}
                  }
                  size {
                    height
                  }
                  overrides {
                    ${frags.commonOverrides}
                    tab
                    onlyMap
                    onlySummary
                    mapShowDataFrom
                    campaignSummary {
                      topMetrics
                      bottomMetrics
                    }
                  }
                  fallbackMap {
                    name
                    dataChecks
                    dataSource
                    geoParams
                    geoNameKey
                    legend
                  }
                }
                ... on fallbackChart {
                  ...CommonPropsFallBack
                  __typename
                }
                ... on progressBarTable {
                  ...CommonPropsProgTable
                  availableBars
                  bars
                  creativeType
                  availableColumns
                  columns
                  columnCount
                  downloadTableData
                  filterBy
                  exportableTableData
                  icon
                  itemLimit
                  hideIfNoData
                  sortBy
                  searchEnabled
                  perPage
                  publishers
                  tooltips {
                    key
                    message
                    title
                  }
                  progressBarColor
                  overrides {
                    ${frags.commonOverrides}
                    itemLimit
                    progressBarColor
                  }
                  noDataTooltips {
                    title
                    key
                    message
                  }
                  additionalDataSource
                  additionalMetrics
                  sort
                }
                ... on genericLineChart {
                  ...CommonPropsLine
                  chartColorIndex
                  chartType
                  commonTweaks
                  dataKey
                  exportableTableData
                  hideEmptySeriesKeys
                  hideIfNoData
                  hideLegend
                  availableLineSeriesKeys
                  lineSeriesKeys
                  showyAxisSplitLine
                  total
                  useTacticSummaryData
                  xAxisIntervalCount
                  xAxisMaxItems
                  xAxisShowAll
                  xAxisSplitLineHide
                  yAxisHide
                  yAxisLineHide
                  xAxisRotateDeg
                  showOneLine
                  chartColors
                  sideTotalMetrics {
                    dataSource
                    metrics
                    useTacticSummaryData
                    hideZeros
                  }
                  tooltips {
                    key
                    message
                    title
                  }
                  noDataTooltips {
                    title
                    key
                    message
                  }
                  overrides {
                    ${frags.commonOverrides}
                    lineSeriesKeys
                    showOneLine
                    chartColors
                    hideFromUI
                  }
                  feedSource
                  feedSourceToExclude
                  additionalDataSource
                  additionalMetrics
                  hideFromUI
                }
                ... on genericPie {
                  ...CommonPropsPie
                  exportableTableData
                  filterable
                  hasBrandImage
                  hideIfNoData
                  nameKey
                  rootValueKey
                  tooltipType
                  valueKey
                  tooltips {
                    title
                    message
                    key
                  }
                  overrides {
                    ${frags.commonOverrides}
                  hideFromUI
                  }
                  noDataTooltips {
                    title
                    key
                    message
                  }
                  feedSource
                  feedSourceToExclude
                  hidePieChart
                  additionalDataSource
                  additionalMetrics
                  hideFromUI
                }
                ... on stationMap {
                  ...CommonPropsStationMap
                  geoNameKey
                  geoParams
                  legend
                  themes
                  onlyMap
                  onlySummary
                  campaignSummary {
                    ${frags.campaignSummaryProps}
                  }
                  size {
                    height
                  }
                  overrides {
                    ${frags.commonOverrides}
                    onlyMap
                    onlySummary
                    campaignSummary {
                      topMetrics
                      bottomMetrics
                    }
                  }
                }
                ... on geofenceMap {
                  ...CommonPropsGeofenceMap
                  legend
                  onlyMap
                  onlySummary
                  campaignSummary {
                    ${frags.campaignSummaryProps}
                  }
                  size {
                    height
                  }
                  overrides {
                    ${frags.commonOverrides}
                    onlyMap
                    onlySummary
                    mapShowDataFrom
                    campaignSummary {
                      topMetrics
                      bottomMetrics
                    }
                  }
                }
                ... on genericLineBarChartNew {
                  ...CommonPropsLineBarNew
                  barSeriesKeys
                  chartType
                  commonTweaks
                  dataKey
                  dataSource
                  exportableTableData
                  hideEmptySeriesKeys
                  lineSeriesKeys
                  xAxisIntervalCount
                  xAxisShowAll
                  tooltips {
                    key
                    message
                    title
                  }
                  noDataTooltips {
                    title
                    key
                    message
                  }
                  overrides {
                    ${frags.commonOverrides}
                  }
                  additionalDataSource
                  additionalMetrics
                  feedSource
                  feedSourceToExclude
                }
                ... on lineAndBarChart {
                  ...CommonPropsLineBar
                  barSeriesKeys
                  chartType
                  commonTweaks
                  dataKey
                  dataSource
                  exportableTableData
                  hideEmptySeriesKeys
                  lineSeriesKeys
                  xAxisIntervalCount
                  xAxisShowAll
                  tooltips {
                    key
                    message
                    title
                  }
                  noDataTooltips {
                    title
                    key
                    message
                  }
                  overrides {
                    ${frags.commonOverrides}
                  }
                  additionalDataSource
                  additionalMetrics
                }
                ... on campaignOverview {
                  ...CommonPropsCampOverview
                  hasImpressionsProgressBar
                  useTacticSummaryData
                  hideZeros
                  topMetrics
                  overrides {
                    ${frags.commonOverrides}
                  }
                }
                ... on genericMap {
                  ...CommonPropsGenericMap
                  geoNameKey
                  geoParams
                  hidePolygonsWithLessThan
                  legend
                  rootValueKey
                  themes
                  onlyMap
                  onlySummary
                  campaignSummary {
                    ${frags.campaignSummaryProps}
                  }
                  size {
                    height
                  }
                  overrides {
                    ${frags.commonOverrides}
                    onlyMap
                    onlySummary
                    campaignSummary {
                      topMetrics
                      bottomMetrics
                    }
                  }
                }
                ... on genericTopMetrics {
                  ...CommonPropsTopMetrics
                  metrics {
                    title
                    topDataFormat
                    topDataKey
                    type
                  }
                  overrides {
                    ${frags.commonOverrides}
                  }
                }
                ... on sideSummary {
                  ...CommonPropsSideSummary
                  useTacticSummaryData
                  metricKeys
                  hideZeros
                  overrides {
                    ${frags.commonOverrides}
                  }
                }
                ... on textCampaignSummary {
                  ...CommonPropsTextCampaignSummary
                  overrides {
                    ${frags.commonOverrides}
                  }
                }
                ... on campaignStats {
                  ...CommonPropsCampaignStats
                  overrides {
                    ${frags.commonOverrides}
                  }
                }
                ... on targetingList {
                  ...CommonPropsTargetingList
                  hideIfNoData
                  exportableTableData
                  downloadTableData
                  feedSource
                  feedSourceToExclude
                  tooltips {
                    key
                    message
                    title
                  }
                  overrides {
                    ${frags.commonOverrides}
                  }
                }
                ... on slingNetworkLogosList {
                  ...CommonPropsLogoList
                  feedSource
                  overrides {
                    ${frags.commonOverrides}
                  }
                  tooltips {
                    key
                    message
                    title
                  }
                }
                ... on siteImpactTable {
                  ...CommonPropsSiteImpactTable
                  columns
                  downloadTableData
                  exportableTableData
                  icon
                  searchEnabled
                  sortBy
                  headers {
                    align
                    sortable
                    text
                    width
                    value
                  }
                  noDataTooltips {
                    title
                    key
                    message
                  }
                  tooltips {
                    key
                    message
                    title
                  }
                  overrides {
                    ${frags.commonOverrides}
                  }
                }
                ... on tableList {
                  ...CommonPropsTableList
                  feedSource
                  feedSourceToExclude
                  hideIfNoData
                  overrides {
                    ${frags.commonOverrides}
                  }
                  tooltips {
                    key
                    message
                    title
                  }
                }
                ... on sectionHeader {
                  ...CommonPropsSectionHeader
                  feedSource
                  feedSourceToExclude
                  hideIfNoData
                  hideFromUI
                  description
                  overrides {
                    ${frags.commonOverrides}
                    hideFromUI
                  }
                  tooltips {
                    key
                    message
                    title
                  }
                }
                ... on vennDiagram {
                  ...CommonPropsVennDiagram
                  feedSource
                  feedSourceToExclude
                  hideIfNoData
                  hideFromUI
                  sortBy
                  filterBy
                  columns
                  availableColumns
                  overrides {
                    ${frags.commonOverrides}
                    hideFromUI
                  }
                  tooltips {
                    key
                    message
                    title
                  }
                }

                ... on benchmarksChart {
                  ...CommonPropsBenchmarksChart
                  hideIfNoData
                  legendKeys
                  tooltips {
                    key
                    message
                    title
                  }
                  dataSource
                  noDataTooltips {
                    title
                    key
                    message
                  }
                  overrides {
                    ${frags.commonOverrides}
                  }
                }
              }
            }
          }
        }

        query GetSingleLayout(
          $tactic: String!
          $agency: String
          $advertiserId: String
          $token: String
          $checkPrevious: Boolean
          $userEmail: String
        ) {
          layoutCustomizations(
            tactic: $tactic
            agency: $agency
            advertiserId: $advertiserId
            token: $token
            checkPrevious: $checkPrevious
            userEmail: $userEmail
          ) {
            ...CommonLayoutFields
            }
          defaultLayout: layoutCustomizations(
            tactic: $tactic
            token: $token
          ) {
            ...CommonLayoutFields
          }
        }
      `,
      variables: {
        agency: params.agency,
        advertiserId: params.advertiserId,
        tactic: params.tactic,
        token: params.token,
        checkPrevious: params.checkPrevious,
        userEmail: params.userEmail,
      },
      fetchPolicy: 'no-cache',
    });
    const data = response.data;
    if (data?.allLayouts) {
      commit(CONSTANTS.SET_LAYOUT_TACTICS, data?.allLayouts);
    }
    return data;
  } catch (error) {
    commit(CONSTANTS.SET_GQL_LOADING, false);
    if (error.message !== 'Error: Layout is not exist.') {
      commit(CONSTANTS.SET_ERROR, error);
    }
    logError('layouts', error);
  } finally {
    commit(CONSTANTS.SET_GQL_LOADING, false);
  }
};

const getTacticsGQL = async ({ commit, state }, params: AllLayoutsArguments): Promise<void> => {
  try {
    // commit(CONSTANTS.SET_LOADING_LAYOUTS, true);

    if (state?.tacticList?.length) {
      return;
    }
    const response: ApolloQueryResult<any> = await apollo().query({
      query: gql`
        query GetTactics($token: String) {
          allLayouts(token: $token) {
            id
            title
            components {
              type
            }
            pdfExport
            pptExport
            pptExportDevOnly
            pptExportLocalOnly
            xlsExport
            xlsExportDevOnly
            xlsExportLocalOnly
            fileNameTemplate
          }
        }
      `,
      variables: {
        token: params?.token,
      },
      fetchPolicy: 'no-cache',
    });

    commit(CONSTANTS.SET_LAYOUT_TACTICS, response.data?.allLayouts);
    return response.data?.allLayouts;
  } catch (error) {
    commit(CONSTANTS.SET_ERROR, error);
    logError('layouts', error);
  } finally {
    // commit(CONSTANTS.SET_LOADING_LAYOUTS, false);
  }
};

const getActivityLog = async ({ commit }, params: ActivityLogArguments): Promise<void> => {
  try {
    // commit(CONSTANTS.SET_LOADING_LAYOUTS, true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: ApolloQueryResult<any> = await apollo().query({
      query: gql`
        query GetActivityLog($endDate: String, $startDate: String) {
          getActivityLog(endDate: $endDate, startDate: $startDate) {
            DataSourceCode
            LastModifiedBy
            LastModifiedDate
            PropertyId
            Version
            advertiserId
            advertiserName
            agencyName
            pstate
            tactic
            type
          }
        }
      `,
      variables: {
        endDate: params.endDate,
        startDate: params.startDate,
      },
      fetchPolicy: 'no-cache',
    });

    // commit(CONSTANTS.SET_LAYOUT_TACTICS, response.data?.allLayouts);
    return response.data?.getActivityLog;
  } catch (error) {
    commit(CONSTANTS.SET_ERROR, error);
    logError('layouts', error);
  } finally {
    // commit(CONSTANTS.SET_LOADING_LAYOUTS, false);
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateLayoutGQL = async ({ commit }, layout): Promise<void> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: ApolloQueryResult<any> = await apollo().mutate({
    mutation: gql`
      mutation UpdateLayout($layout: TacticInput!) {
        updateLayout(layout: $layout) {
          type
          tactic
          pstate
          agencyName
          PropertyId
          LastModifiedDate
          DataSourceCode
        }
      }
    `,
    variables: {
      layout: layout,
    },
    fetchPolicy: 'no-cache',
  });
  return response.data;
};

const saveLayoutCustomizations = async (
  { commit },
  payload: { layout; customizations; userEmail; type; reset },
): Promise<Status> => {
  try {
    commit(CONSTANTS.SET_GQL_LOADING, true);
    // clone so to not affect current layout
    const cleanLayout = sanitizeLayoutData(_clone(payload.layout));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: ApolloQueryResult<any> = await apollo().mutate({
      mutation: gql`
        mutation SaveLayoutCustomizations(
          $layout: TacticInput!
          $customizations: [ComponentInput]
          $userEmail: String
          $type: String
          $reset: Boolean
        ) {
          saveLayoutCustomizations(
            layout: $layout
            customizations: $customizations
            userEmail: $userEmail
            type: $type
            reset: $reset
          ) {
            type
            tactic
            pstate
            agencyName
            PropertyId
            LastModifiedDate
            DataSourceCode
            Version
          }
        }
      `,
      variables: {
        layout: cleanLayout,
        customizations: payload?.customizations,
        userEmail: payload?.userEmail,
        type: payload?.type,
        reset: payload?.reset,
      },
      fetchPolicy: 'no-cache',
    });
    return { data: response.data };
  } catch (error) {
    commit(CONSTANTS.SET_GQL_LOADING, false);
    commit(CONSTANTS.SET_ERROR, error);
    logError('saveCustomizations', error);
    return { data: null, error: true };
  } finally {
    commit(CONSTANTS.SET_GQL_LOADING, false);
  }
};

const sanitizeLayoutData = (obj): object => {
  // config seems to be an array only sometimes?
  let config;
  if (Array.isArray(obj.config)) {
    config = obj.config[0];
  } else {
    config = obj.config;
  }

  // simplify components (both sets if has summary view comps array)
  config.components.forEach(comps => {
    delete comps.__typename;
    comps.components = comps.components.map(c => {
      return {
        cid: c.cid,
        overrides: c.overrides,
        id: c.id,
      };
    });
  });

  // TODO: might need to be rethought, maybe need to be consistent on updateLayout()
  const inputKeys = [
    'enableCampaignSelection',
    'shouldUsingTabs',
    'hideCampainSelect',
    'useSummaryData',
    'pdfExport',
    'xlsExport',
    'xlsExportDevOnly',
    'xlsExportLocalOnly',
    'pdfExportLocalOnly',
    'pdfExportDevOnly',
    'pptExport',
    'pptExportLocalOnly',
    'pptExportDevOnly',
    'fileNameTemplate',
    'markup',
    'exportTab',
  ];

  inputKeys.forEach(key => {
    if (!obj.config[key]) {
      obj.config[key] = null;
    }
  });

  obj.pstate = `${obj.pstate}`;

  delete obj.__typename;
  delete obj.config.__typename;

  return obj;
};

const getScheduledReportsLog = async (
  { commit },
  params: { limit: number; offset: number; search: string; orderBy: string },
): Promise<void> => {
  try {
    // Calculate the date six months ago from today
    const sixMonthsAgo = sub(new Date(), { months: 6 });
    const sixMonthsAgoDate = lightFormat(sixMonthsAgo, 'yyyy-MM-dd');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: ApolloQueryResult<any> = await apollo().query({
      query: gql`
        query GetScheduledReportsLog($limit: Int, $offset: Int, $name: String, $after: String, $orderBy: String) {
          getScheduledReportsLog(limit: $limit, offset: $offset, name: $name, after: $after, orderBy: $orderBy) {
            logs {
              logId
              reportId
              reportName
              scheduledTo
              timezone
              createdBy
              startDate
              endDate
              lastUpdateTime
              status
              error {
                cause {
                  code
                  name
                  message
                }
              }
              advertiserId
              agencyId
              additional {
                Format
                Products
                Campaigns
                TimezoneOffset
                ParentAdvertiser {
                  Name
                  PropertyId
                  AgencyPartner
                }
                ReportDateRangeKind
              }
              recipients
            }
            paginationInfo {
              totalObjects
              totalPages
              currentPage
            }
            limit
            offset
          }
        }
      `,
      variables: {
        limit: params.limit,
        offset: params.offset,
        name: params.search,
        after: sixMonthsAgoDate,
        orderBy: params.orderBy,
      },
      fetchPolicy: 'no-cache',
    });

    return response.data?.getScheduledReportsLog;
  } catch (error) {
    commit(CONSTANTS.SET_ERROR, error);
    logError('scheduledReportsLog', error);
  } finally {
    // handle any cleanup if needed
  }
};

export default {
  getTacticsGQL,
  getLayoutsGQL,
  getActivityLog,
  updateLayoutGQL,
  saveLayoutCustomizations,
  getScheduledReportsLog,
};

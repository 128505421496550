import Vue from 'vue';
import _union from 'lodash.union';
import { Theme } from '@point/utility-classes';
import CONSTANTS from './constants';
import { Campaigns, StateType, AuthUser, LoadedDateRanges, LoadedAdvertisers } from './types';
import { LayoutSection } from '../../../types/layout';

export default {
  SET_USER(_state: StateType, _user: AuthUser | null): void {
    // merge with any domain layouts
    _user.LayoutRight = _union(_user.LayoutRight, Vue.prototype.Theme.layouts);
    Vue.set(_state, 'user', _user);
  },
  SET_ERROR(_state: StateType, _error: object | null): void {
    Vue.set(_state, 'error', _error);
  },
  AUTHCHECK_DONE(_state: StateType, done: boolean): void {
    Vue.set(_state, 'waitingForAuth', !done);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_DEBUG_FLAG](_state: any, keyval: any): void {
    if (typeof keyval === 'string') {
      Vue.set(_state, 'debugFlags', { ..._state.debugFlags, [keyval]: true });
    } else if (typeof keyval === 'object' && keyval.key) {
      Vue.set(_state, 'debugFlags', {
        ..._state.debugFlags,
        [keyval.key]: keyval.value,
      });
    } else {
      // eslint-disable-next-line no-console
      console.log('SET_DEBUG_FLAG unhandled keyval', keyval);
    }
  },
  [CONSTANTS.SET_NAV_TAB](_state, tab: string): void {
    Vue.set(_state, 'currentNavTab', tab);
  },
  [CONSTANTS.SET_CURRENT_SECTION](_state, section: LayoutSection): void {
    Vue.set(_state, 'currentSection', section);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_SUMMARY_PERFORMANCE](_state: any, _data: any): void {
    if (_data) {
      Vue.set(_state, 'summaryPerformance', _data);
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_TACTIC_SUMMARY_PERFORMANCE](_state: any, _data: any): void {
    if (_data) {
      Vue.set(_state, 'tacticSummaryPerformance', _data);
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_AD_PERFORMANCE](_state: any, _data: any): void {
    if (_data) {
      Vue.set(_state, 'adPerformance', _data);
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_HAS_RIGHT_LOADING](_state: any, status: boolean): void {
    if (status) Vue.set(_state, 'loadingHasRight', status);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE](_state: any, _data: any): void {
    if (_data) {
      Vue.set(_state, 'campaignAdPerformance', _data);
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE](_state: any, _data: any): void {
    if (_data) {
      Vue.set(_state, 'allTimeAdPerformance', _data);
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_ALL_TIME_CAMPAIGNS](_state: any, _data: any): void {
    if (_data) {
      Vue.set(_state, 'allTimeCampaigns', _data);
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_DMA_PERFORMANCE](_state: any, _data: any): void {
    if (_data) {
      Vue.set(_state, 'adPerformance', _data);
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_POLYGONS](_state: any, _data: any): void {
    if (_data) {
      Vue.set(_state, 'advertiserPolygons', _data);
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_DMA_POLYGONS](_state: any, _data: any): void {
    if (_data) {
      Vue.set(_state, 'dmaPolygons', _data);
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_ZIP_POLYGONS](_state: any, _data: any): void {
    if (_data) {
      Vue.set(_state, 'zipPolygons', _data);
    }
  },
  [CONSTANTS.SET_DATERANGES](_state, _data: LoadedDateRanges): void {
    if (_data) {
      Vue.set(_state, 'dateRanges', _data);
    }
  },
  [CONSTANTS.SET_ADVERTISERS](_state, _data: LoadedAdvertisers): void {
    if (_data) {
      Vue.set(_state, 'advertisers', _data);
    }
  },
  [CONSTANTS.SET_SUMMARY_PERFORMANCE_LOADING](_state, status): void {
    Vue.set(_state, 'loadingSummaryPerformance', status);
  },
  [CONSTANTS.SET_TACTIC_SUMMARY_PERFORMANCE_LOADING](_state, status): void {
    Vue.set(_state, 'loadingTacticSummaryPerformance', status);
  },
  [CONSTANTS.SET_AD_PERFORMANCE_LOADING](_state, status): void {
    Vue.set(_state, 'loadingAdPerformance', status);
  },
  [CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE_LOADING](_state, status): void {
    Vue.set(_state, 'loadingAllTimeAdPerformance', status);
  },
  [CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE_LOADING](_state, status): void {
    Vue.set(_state, 'loadingCampaignAdPerformance', status);
  },
  [CONSTANTS.SET_NETWORK_ACTIVITY](_state, status): void {
    let counter = _state.networkActivityCounter;
    if (status) {
      counter += 1;
    } else {
      counter -= 1;
    }
    if (counter < 0) {
      counter = 0;
    }
    Vue.set(_state, 'networkActivityCounter', counter);
  },
  [CONSTANTS.SET_FETCHING](_state, payload): void {
    let fetching = _state.fetching;
    const fetchingComponents = _state.fetchingComponents;
    let key;
    if (payload.url) {
      key = `${payload.cid}:${payload.url}`;
    } else {
      key = payload.cid;
    }
    if (!payload?.completed) {
      fetchingComponents.add(payload.cid);
      fetching.push(key);
    } else {
      fetching = fetching.filter(entry => entry !== key);
    }
    Vue.set(_state, 'fetching', fetching);
    Vue.set(_state, 'fetchingComponents', fetchingComponents);
  },
  [CONSTANTS.SET_POLYGONS_LOADING](_state, status): void {
    Vue.set(_state, 'loadingAdvertiserPolygons', status);
  },
  [CONSTANTS.SET_GEOCODES_LOADING](_state, status): void {
    Vue.set(_state, 'loadingAdvertiserGeocodes', status);
  },
  [CONSTANTS.SET_DMA_POLYGONS_LOADING](_state, status): void {
    Vue.set(_state, 'loadingDMAPolygons', status);
  },
  [CONSTANTS.SET_ZIP_POLYGONS_LOADING](_state, status): void {
    Vue.set(_state, 'loadingZipPolygons', status);
  },
  [CONSTANTS.SET_SHARE_CONTENT_LOADING](_state, status): void {
    Vue.set(_state, 'loadingShareContent', status);
  },
  [CONSTANTS.SET_EXPORT_CONTENT](_state, status): void {
    Vue.set(_state, 'exportingData', status);
  },
  [CONSTANTS.SET_EXPORT_CONTENT_LOADING](_state, status): void {
    Vue.set(_state, 'loadingExportContent', status);
  },
  [CONSTANTS.SET_DASHBOARD_SHARED](_state, status): void {
    Vue.set(_state, 'sharedDashboard', status);
  },
  [CONSTANTS.SET_SHARED_SELECTION](_state, selection): void {
    Vue.set(_state, 'sharedSelection', selection);
  },
  [CONSTANTS.SET_SELECTION](_state, newSelection: Selection): void {
    _state.selection = { ..._state.selection, ...newSelection };
  },
  [CONSTANTS.SET_ADBLOCK](_state, flag: boolean): void {
    _state.adBlockDetected = flag;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_LAYOUT](_state, layout: any): void {
    _state.dynamicLayout = layout;
  },
  [CONSTANTS.ADD_MODULE](_state, data): void {
    const { compSetIndex, newModule, makeFirst, hasAdvLevel } = data;
    if (hasAdvLevel) {
      _state.dynamicLayout.layoutCustomizations.config.components[compSetIndex].components.splice(
        hasAdvLevel,
        0,
        newModule,
      );
      return;
    }

    if (makeFirst) {
      _state.dynamicLayout.layoutCustomizations.config.components[compSetIndex].components.unshift(newModule);
    } else {
      _state.dynamicLayout.layoutCustomizations.config.components[compSetIndex].components.push(newModule);
    }
  },

  [CONSTANTS.SET_OVERRIDES](_state, data): void {
    const { overrides, compSetIndex, compIndex } = data;
    _state.dynamicLayout.layoutCustomizations.config.components[compSetIndex].components[compIndex].overrides =
      overrides;
    Object.keys(overrides).forEach(key => {
      if (key === 'campaignSummary') {
        const { campaignSummary } =
          _state.dynamicLayout.layoutCustomizations.config.components[compSetIndex].components[compIndex];
        Object.keys(overrides.campaignSummary).forEach(subKey => {
          campaignSummary[subKey] = overrides.campaignSummary[subKey];
        });
      } else {
        _state.dynamicLayout.layoutCustomizations.config.components[compSetIndex].components[compIndex][key] =
          overrides[key];
      }
    });
  },
  [CONSTANTS.SET_LAYOUT_TYPE](_state, layoutType: string): void {
    _state.dynamicLayout.layoutCustomizations.type = layoutType;
  },
  [CONSTANTS.SET_LAYOUT_AGENCY_NAME](_state, name: string): void {
    _state.dynamicLayout.layoutCustomizations.agencyName = name;
  },
  [CONSTANTS.SET_LAYOUT_ADVERTISER_ID](_state, id: string): void {
    _state.dynamicLayout.layoutCustomizations.advertiserId = id;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_LAYOUT_VERSION](_state, version: any): void {
    _state.dynamicLayout.layoutCustomizations.Version = version;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_LAYOUT_SOURCE](_state, layout: any): void {
    _state.dynamicLayoutSource = layout;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_DEFAULT_LAYOUT](_state, layout: any): void {
    _state.defaultLayout = layout;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_LAYOUT_TACTICS](_state, tacticList: any): void {
    _state.tacticList = tacticList;
  },
  [CONSTANTS.SET_LAYOUT_LOADING](_state, status): void {
    Vue.set(_state, 'loadingLayouts', status);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_DASHBOARD](_state, dashboard: any): void {
    _state.dashboard = dashboard;
  },
  [CONSTANTS.SET_THEME](_state, theme: Theme): void {
    _state.theme = theme;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_STATIC_LAST_MODIFIED_DATE](_state: any, lastModifiedDate: string): void {
    if (lastModifiedDate) {
      Vue.set(_state, 'staticLastModifiedDate', lastModifiedDate);
    }
  },
  [CONSTANTS.SET_CAMPAIGNS](_state, campaigns: Campaigns): void {
    _state.campaigns = campaigns;
  },
  [CONSTANTS.UPDATE_CAMPAIGNS](_state, campaigns: Campaigns): void {
    _state.campaigns = campaigns;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_GOOGLE_CREDENTIALS](_state: any, _data: any): void {
    if (_data) {
      Vue.set(_state, 'googleCredentialsId', _data);
    }
  },
  [CONSTANTS.SET_EDIT_THEME](_state, themeData): void {
    Vue.set(_state, 'editTheme', themeData);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_SHARED_DATERANGES](_state: StateType, shared: any): void {
    Vue.set(_state, 'sharedDateRanges', shared);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_SHARED_CAMPAIGNS](_state: StateType, shared: any): void {
    Vue.set(_state, 'sharedCampaigns', shared);
  },
  [CONSTANTS.SET_PRODUCTS_LOADING](_state: StateType, loading: boolean): void {
    Vue.set(_state, 'loadingProducts', loading);
  },
  [CONSTANTS.SET_PRODUCTS](_state: StateType, products: object): void {
    Vue.set(_state.currentDashboard, 'products', products);
  },
  [CONSTANTS.SET_VALIDATED_PRODUCT_SECTIONS](_state: StateType, products: object): void {
    Vue.set(_state.currentDashboard, 'validatedProductSections', products);
  },
  [CONSTANTS.SET_GQL_LOADING](_state: StateType, loading: boolean): void {
    Vue.set(_state, 'loadingGQL', loading);
  },
  [CONSTANTS.SET_NOTE_ERROR](_state: StateType, error: string): void {
    Vue.set(_state, 'noteError', error);
  },
  [CONSTANTS.SET_EXPORT_STATE](_state, state): void {
    Vue.set(_state, 'exportState', state);
  },
  [CONSTANTS.SET_DASHX_EXPORT_STATE](_state, state): void {
    Vue.set(_state, 'dashxExportState', state);
  },
  SET_PROCESSING(_state, processing): void {
    Vue.set(_state, 'processing', processing);
  },
  SET_USER_ACCESS_TOKEN(_state: StateType, _token: string): void {
    Vue.set(_state.user, 'accessToken', _token);
  },
  SET_ORDER_LIST_STATE(_state, orderListState): void {
    Vue.set(_state, 'orderListState', orderListState);
  },
  [CONSTANTS.SET_SUMMARY_DATE_RANGES](_state, dateRanges: object): void {
    if (dateRanges) {
      Vue.set(_state, 'summaryDateRanges', dateRanges);
    }
  },
  [CONSTANTS.SET_CAMPAIGN_SPEND_RATE](_state, state): void {
    if (_state.campaignAdPerformance?.CampaignList?.[0]) {
      _state.campaignAdPerformance.CampaignList[0] = state;
    }
  },
  [CONSTANTS.SET_CLICKED_TACTIC_FROM_SIDEBAR](_state, state): void {
    _state.clickedTacticFromSidebar = state;
  },
};

/* eslint-disable camelcase */
import axios, { AxiosResponse } from 'axios';
import CONSTANTS from './constants';
import { logError } from '../../index';
import { session_id } from '../../../main';
import { Conversions } from '@point/utility-classes';
import {
  ConversionsPayload,
  OrderConversionsPayload,
  DailyPerformancePayload,
  GeoPerformancePayload,
  GeoListPerformancePayload,
  ConversionsBreakdownTypeRawData,
} from './types';

const getConversions = async ({ commit, state }, payload: ConversionsPayload): Promise<Conversions> => {
  const { advertiserId, campaignDashboardIds, resolution, daterange, startdate = '', enddate = '', token } = payload;
  commit(CONSTANTS.SET_CONVERSIONS_ERROR, null);

  if (!advertiserId || !daterange || !campaignDashboardIds || !resolution) {
    commit(CONSTANTS.SET_CONVERSIONS_ERROR, 'Missing parameters');
    return;
  }

  let conversionsUrl;
  const headers = { session_id };

  if ((!startdate || !enddate) && daterange) {
    conversionsUrl = `/api/advertiser/conversion?advertiserId=${advertiserId}&daterange=${daterange}&campaignDashboardIds=${campaignDashboardIds}&resolution=${resolution}`;
  } else {
    conversionsUrl = `/api/advertiser/conversion?advertiserId=${advertiserId}&campaignDashboardIds=${campaignDashboardIds}&resolution=${resolution}&startDate=${startdate}&endDate=${enddate}`;
  }

  if (token) {
    conversionsUrl += `&token=${token}`;
  }

  if (
    state.conversionsCache === conversionsUrl &&
    state.isConversionsCached &&
    state.conversions &&
    !state.conversionsError
  ) {
    return state.conversions;
  }

  try {
    commit(CONSTANTS.SET_IS_CONVERSIONS_LOADING, true);
    const { data }: AxiosResponse<Conversions> = await axios.get(conversionsUrl, { headers });
    commit(CONSTANTS.SET_CONVERSIONS, data);
    commit(CONSTANTS.SET_CONVERSIONS_CACHE, conversionsUrl);
    commit(CONSTANTS.SET_IS_CONVERSIONS_CACHED, true);

    return data;
  } catch (exp) {
    commit(CONSTANTS.SET_CONVERSIONS_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.performance.getConversions');
  } finally {
    commit(CONSTANTS.SET_IS_CONVERSIONS_LOADING, false);
  }
};

const getOrderConversions = async ({ commit, state }, payload: OrderConversionsPayload): Promise<Conversions> => {
  const { advertiserId, orderId, resolution, daterange } = payload;
  commit(CONSTANTS.SET_ORDER_CONVERSIONS_ERROR, null);

  if (!advertiserId || !orderId || !resolution) {
    commit(CONSTANTS.SET_ORDER_CONVERSIONS_ERROR, 'Missing parameters');
    return;
  }

  const headers = { session_id };
  const conversionsUrl = `/api/advertiser/order/conversion?advertiserId=${advertiserId}&orderId=${orderId}&resolution=${resolution}&daterange=${daterange}`;

  if (
    state.orderConversionsCache === conversionsUrl &&
    state.isOrderConversionsCached &&
    state.orderConversions &&
    !state.orderConversionsError
  ) {
    return state.orderConversions;
  }

  try {
    commit(CONSTANTS.SET_IS_ORDER_CONVERSIONS_LOADING, true);
    const { data }: AxiosResponse<Conversions> = await axios.get(conversionsUrl, { headers });
    commit(CONSTANTS.SET_ORDER_CONVERSIONS, data);
    commit(CONSTANTS.SET_ORDER_CONVERSIONS_CACHE, conversionsUrl);
    commit(CONSTANTS.SET_IS_ORDER_CONVERSIONS_CACHED, true);

    return data;
  } catch (exp) {
    commit(CONSTANTS.SET_ORDER_CONVERSIONS_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.performance.getOrderConversions');
  } finally {
    commit(CONSTANTS.SET_IS_ORDER_CONVERSIONS_LOADING, false);
  }
};

const getConversionsBreakdown = async ({ commit, state }, payload): Promise<ConversionsBreakdownTypeRawData> => {
  const { advertiserId, daterange, campaignsIds, type, startdate = '', enddate = '', token } = payload;
  commit(CONSTANTS.SET_CONVERSIONS_BREAKDOWN_ERROR, null);

  if (!advertiserId || !daterange || !campaignsIds?.length) {
    commit(CONSTANTS.SET_CONVERSIONS_BREAKDOWN_ERROR, 'Missing parameters');
    return;
  }

  const campaigns = campaignsIds?.map(c => {
    return {
      id: c,
      type: type,
    };
  });

  const body: Record<string, unknown> = {
    advertiserId,
    daterange,
    campaigns,
  };

  const conversionBreakdownUrl = `/api/advertiser/performance/getConversionBreakdown`;
  const conversionBreakdownUrlCache = `/api/advertiser/performance/getConversionBreakdown?advertiserId=${advertiserId}&daterange=${daterange}&startdate=${startdate}&enddate=${enddate}&tab=${type}&campaigns=${campaignsIds}`;
  const headers = { session_id };

  if (!startdate || !enddate) {
    body.daterange = daterange;
  } else {
    body.startDate = startdate;
    body.endDate = enddate;
    body.daterange = 'alltime';
  }

  if (token) {
    body.token = token;
  }

  if (
    !token &&
    state.conversionBreakdownCacheUrl === conversionBreakdownUrlCache &&
    state.isConversionBreakdownCached &&
    state.conversionsBreakdown &&
    !state.conversionsBreakdownError
  ) {
    return state.conversionsBreakdown;
  }

  try {
    commit(CONSTANTS.SET_IS_CONVERSIONS_BREAKDOWN_LOADING, true);
    const { data }: AxiosResponse<ConversionsBreakdownTypeRawData> = await axios.post(conversionBreakdownUrl, body, {
      headers,
    });
    commit(CONSTANTS.SET_CONVERSIONS_BREAKDOWN, data);
    commit(CONSTANTS.SET_CONVERSIONS_BREAKDOWN_CACHE_URL, conversionBreakdownUrlCache);
    commit(CONSTANTS.SET_IS_CONVERSIONS_BREAKDOWN_CACHED, true);
    return data;
  } catch (exp) {
    commit(CONSTANTS.SET_CONVERSIONS_BREAKDOWN_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.performance.getConversionsBreakdown');
  } finally {
    commit(CONSTANTS.SET_IS_CONVERSIONS_BREAKDOWN_LOADING, false);
  }
};

const getDailyPerformance = async ({ commit, state }, payload: DailyPerformancePayload): Promise<unknown> => {
  const { advertiserId, daterange, campaignsIds, type, startdate = '', enddate = '', token } = payload;
  commit(CONSTANTS.SET_DAILY_PERFORMANCE_ERROR, null);

  if (!advertiserId || !daterange || !campaignsIds?.length) {
    commit(CONSTANTS.SET_DAILY_PERFORMANCE_ERROR, 'Missing parameters');
    return;
  }

  const campaigns = campaignsIds?.map(c => {
    return {
      id: c,
      type: type,
    };
  });

  const body: Record<string, unknown> = {
    advertiserId,
    daterange,
    campaigns,
  };

  const dailyPerformanceUrl = `/api/advertiser/performance/daily`;
  const dailyPerformanceUrlCache = `/api/advertiser/performance/daily?advertiserId=${advertiserId}&daterange=${daterange}&startdate=${startdate}&enddate=${enddate}&tab=${type}&campaigns=${campaignsIds}`;
  const headers = { session_id };

  if (!startdate || !enddate) {
    body.daterange = daterange;
  } else {
    body.startDate = startdate;
    body.endDate = enddate;
    body.daterange = 'alltime';
  }

  if (token) {
    body.token = token;
  }

  if (
    !token &&
    state.dailyPerformanceCacheUrl === dailyPerformanceUrlCache &&
    state.isDailyPerformanceCached &&
    state.dailyPerformance &&
    !state.dailyPerformanceError
  ) {
    return state.dailyPerformance;
  }
  try {
    commit(CONSTANTS.SET_IS_DAILY_PERFORMANCE_LOADING, true);
    const data: AxiosResponse<unknown> = await axios.post(dailyPerformanceUrl, body, { headers });
    commit(CONSTANTS.SET_DAILY_PERFORMANCE, data);
    commit(CONSTANTS.SET_DAILY_PERFORMANCE_CACHE_URL, dailyPerformanceUrlCache);
    commit(CONSTANTS.SET_IS_DAILY_PERFORMANCE_CACHED, true);
    return data;
  } catch (exp) {
    commit(CONSTANTS.SET_DAILY_PERFORMANCE_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.performance.getDailyPerformance');
  } finally {
    commit(CONSTANTS.SET_IS_DAILY_PERFORMANCE_LOADING, false);
  }
};

const getGeoPerformance = async ({ commit }, payload: GeoPerformancePayload): Promise<unknown> => {
  const { advertiserId, daterange, campaigns, startdate = '', enddate = '' } = payload;
  commit(CONSTANTS.SET_GEO_PERFORMANCE_ERROR, null);

  if (!advertiserId || !daterange || !campaigns?.length) {
    commit(CONSTANTS.SET_GEO_PERFORMANCE_ERROR, 'Missing parameters');
    return;
  }

  const body: Record<string, unknown> = {
    advertiserId,
    daterange,
    campaigns,
  };

  const geoPerformanceUrl = `/api/advertiser/performance/geo-performance`;
  const headers = { session_id };

  if (!startdate || !enddate) {
    body.daterange = daterange;
  } else {
    body.startDate = startdate;
    body.endDate = enddate;
    body.daterange = 'alltime';
  }
  try {
    commit(CONSTANTS.SET_IS_GEO_PERFORMANCE_LOADING, true);
    const data: AxiosResponse<unknown> = await axios.post(geoPerformanceUrl, body, { headers });
    commit(CONSTANTS.SET_GEO_PERFORMANCE, data);
    return data;
  } catch (exp) {
    commit(CONSTANTS.SET_GEO_PERFORMANCE_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.performance.getGeoPerformance');
  } finally {
    commit(CONSTANTS.SET_IS_GEO_PERFORMANCE_LOADING, false);
  }
};

const getGeoListPerformance = async ({ commit, state }, payload: GeoListPerformancePayload): Promise<unknown> => {
  const { advertiserId, daterange, campaigns, startdate = '', enddate = '', isShared } = payload;
  commit(CONSTANTS.SET_GEO_PERFORMANCE_ERROR, null);

  if (!advertiserId || !daterange || !campaigns?.length) {
    commit(CONSTANTS.SET_GEO_PERFORMANCE_ERROR, 'Missing parameters');
    return;
  }

  const body: Record<string, unknown> = {
    advertiserId,
    daterange,
    campaigns,
    isShared,
  };
  const campainIds = campaigns.map(c => c.id).join(',');
  const tab = campaigns[0].type;

  const geoListPerformanceUrlCache = `/api/advertiser/performance/geo-list?advertiserId=${advertiserId}&daterange=${daterange}&startdate=${startdate}&enddate=${enddate}&tab=${tab}&campaigns=${campainIds}&isShared=${isShared}`;

  const geoListPerformanceUrl = `/api/advertiser/performance/geo-list`;

  const headers = { session_id };

  if (!startdate || !enddate) {
    body.daterange = daterange;
  } else {
    body.startDate = startdate;
    body.endDate = enddate;
    body.daterange = daterange || 'alltime';
  }

  if (
    state.geoListCacheUrl === geoListPerformanceUrlCache &&
    state.isGeoListCached &&
    state.geoListPerformance &&
    !state.geoListPerformanceError
  ) {
    return state.geoListPerformance;
  }

  try {
    commit(CONSTANTS.SET_IS_GEO_LIST_PERFORMANCE_LOADING, true);
    const data: AxiosResponse<unknown> = await axios.post(geoListPerformanceUrl, body, { headers });
    commit(CONSTANTS.SET_GEO_LIST_PERFORMANCE, data);
    commit(CONSTANTS.SET_GEO_LIST_PERFORMANCE_CACHE_URL, geoListPerformanceUrlCache);
    commit(CONSTANTS.SET_IS_GEO_LIST_PERFORMANCE_CACHED, true);
    return data;
  } catch (exp) {
    commit(CONSTANTS.SET_GEO_LIST_PERFORMANCE_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.performance.getGeoListPerformance');
  } finally {
    commit(CONSTANTS.SET_IS_GEO_LIST_PERFORMANCE_LOADING, false);
  }
};

const getCampaignKpi = async ({ commit }, payload): Promise<unknown> => {
  const { advertiserId, campaignId, type, token } = payload;
  commit(CONSTANTS.SET_CAMPAIGN_KPI_ERROR, null);

  if (!advertiserId || !campaignId || !type) {
    commit(CONSTANTS.SET_CAMPAIGN_KPI_ERROR, 'Missing parameters');
    return;
  }

  const campaignKpiUrl = `/api/getCampaignKpi`;
  const headers = { session_id };

  const body: Record<string, unknown> = {
    advertiser_id: advertiserId,
    id: campaignId,
    type,
  };

  if (token) {
    body.token = token;
  }

  try {
    commit(CONSTANTS.SET_IS_CAMPAIGN_KPI_LOADING, true);
    const res: AxiosResponse<unknown> = await axios.post(campaignKpiUrl, body, { headers });
    commit(CONSTANTS.SET_CAMPAIGN_KPI, res.data);
    return res.data;
  } catch (exp) {
    commit(CONSTANTS.SET_CAMPAIGN_KPI_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.performance.getCampaignKpi');
  } finally {
    commit(CONSTANTS.SET_IS_CAMPAIGN_KPI_LOADING, false);
  }
};

export default {
  getConversions,
  getOrderConversions,
  getConversionsBreakdown,
  getDailyPerformance,
  getGeoPerformance,
  getGeoListPerformance,
  getCampaignKpi,
};

import axios from 'axios';
import CONSTANTS, { ERROR, errorManyAdverts } from './constants';
import { MetricsData, ImpressionsByTactic, ImpressionsByClient } from './types';

async function fetchDataWithCache({
  state,
  commit,
  url,
  cacheKey,
  requestFlagKey,
  cacheKeyCommit,
  requestFlagKeyCommit,
  daterange,
}) {
  if (state[cacheKey] && state[cacheKey][daterange]) {
    return state[cacheKey][daterange];
  }

  if (state[requestFlagKey]) {
    await state[requestFlagKey];
    return state[cacheKey][daterange];
  }
  const requestPromise = makeRequest({ commit, url, cacheKeyCommit, requestFlagKeyCommit, daterange });

  commit(requestFlagKeyCommit, requestPromise);
  return requestPromise;
}

const makeRequest = async ({ commit, url, cacheKeyCommit, requestFlagKeyCommit, daterange }) => {
  try {
    commit(CONSTANTS.SET_PRIMARY_METRICS_ERROR, null);
    const { data } = await axios.get(url);
    if (data?.error) {
      data?.error.status === ERROR
        ? commit(CONSTANTS.SET_PRIMARY_METRICS_ERROR, errorManyAdverts)
        : commit(CONSTANTS.SET_PRIMARY_METRICS_ERROR, data?.error?.message);
    } else {
      commit(cacheKeyCommit, { daterange, data });
    }
    return data ?? [];
  } catch (error) {
    error.status === ERROR
      ? commit(CONSTANTS.SET_PRIMARY_METRICS_ERROR, errorManyAdverts)
      : commit(CONSTANTS.SET_PRIMARY_METRICS_ERROR, error?.message);
    return Promise.reject(error);
  } finally {
    commit(requestFlagKeyCommit, null);
  }
};

const getActiveOrders = async ({ commit, state }, { daterange }): Promise<MetricsData> => {
  return fetchDataWithCache({
    state,
    commit,
    url: `/api/performance-overview/active-orders/${daterange}`,
    cacheKey: 'activeOrdersCache',
    requestFlagKey: 'activeOrdersPromise',
    cacheKeyCommit: CONSTANTS.SET_ACTIVE_ORDERS_CACHE,
    requestFlagKeyCommit: CONSTANTS.SET_ACTIVE_ORDERS_PROMISE,
    daterange,
  });
};

const getImpressions = async ({ commit, state }, { daterange }): Promise<MetricsData> => {
  return fetchDataWithCache({
    state,
    commit,
    url: `/api/performance-overview/impressions/${daterange}`,
    cacheKey: 'impressionsCache',
    requestFlagKey: 'impressionsPromise',
    cacheKeyCommit: CONSTANTS.SET_IMPRESSIONS_CACHE,
    requestFlagKeyCommit: CONSTANTS.SET_IMPRESSIONS_PROMISE,
    daterange,
  });
};

const getActiveClients = async ({ commit, state }, { daterange }): Promise<MetricsData> => {
  return fetchDataWithCache({
    state,
    commit,
    url: `/api/performance-overview/active-advertisers/${daterange}`,
    cacheKey: 'activeClientsCache',
    requestFlagKey: 'activeClientsPromise',
    cacheKeyCommit: CONSTANTS.SET_ACTIVE_CLIENTS_CACHE,
    requestFlagKeyCommit: CONSTANTS.SET_ACTIVE_CLIENTS_PROMISE,
    daterange,
  });
};

const getActiveCampaigns = async ({ commit, state }, { daterange }): Promise<MetricsData> => {
  return fetchDataWithCache({
    state,
    commit,
    url: `/api/performance-overview/active-campaigns/${daterange}`,
    cacheKey: 'activeCampaignsCache',
    requestFlagKey: 'activeCampaignsPromise',
    cacheKeyCommit: CONSTANTS.SET_ACTIVE_CAMPAIGNS_CACHE,
    requestFlagKeyCommit: CONSTANTS.SET_ACTIVE_CAMPAIGNS_PROMISE,
    daterange,
  });
};

const getImpressionsByTactic = async ({ commit }, { daterange }): Promise<ImpressionsByTactic[]> => {
  try {
    commit(CONSTANTS.SET_PRIMARY_IMPRESSIONS_BY_TACTICS_ERROR, null);
    const { data } = await axios.get(`/api/performance-overview/impressions/by-tactic/${daterange}`);
    if (data?.error) {
      data?.error.status === ERROR
        ? commit(CONSTANTS.SET_PRIMARY_IMPRESSIONS_BY_TACTICS_ERROR, errorManyAdverts)
        : commit(CONSTANTS.SET_PRIMARY_IMPRESSIONS_BY_TACTICS_ERROR, data?.error?.message);
    }
    return data;
  } catch (error) {
    error.status === ERROR
      ? commit(CONSTANTS.SET_PRIMARY_IMPRESSIONS_BY_TACTICS_ERROR, errorManyAdverts)
      : commit(CONSTANTS.SET_PRIMARY_IMPRESSIONS_BY_TACTICS_ERROR, error?.message);
    return error;
  }
};

const getImpressionsByClient = async ({ commit }, { daterange }): Promise<ImpressionsByClient[]> => {
  try {
    commit(CONSTANTS.SET_PRIMARY_IMPRESSIONS_BY_CLIENTS_ERROR, null);
    const { data } = await axios.get(`/api/performance-overview/impressions/by-clients/${daterange}`);
    if (data?.error) {
      data?.error.status === ERROR
        ? commit(CONSTANTS.SET_PRIMARY_IMPRESSIONS_BY_CLIENTS_ERROR, errorManyAdverts)
        : commit(CONSTANTS.SET_PRIMARY_IMPRESSIONS_BY_CLIENTS_ERROR, data?.error?.message);
    }
    return data;
  } catch (error) {
    error.status === ERROR
      ? commit(CONSTANTS.SET_PRIMARY_IMPRESSIONS_BY_CLIENTS_ERROR, errorManyAdverts)
      : commit(CONSTANTS.SET_PRIMARY_IMPRESSIONS_BY_CLIENTS_ERROR, error?.message);
    return error;
  }
};

const getEndingSoonOrders = async ({ commit }, { limit, offset, daterange }): Promise<ImpressionsByClient[]> => {
  commit(CONSTANTS.SET_ENDING_ORDERS_ERROR, null);
  try {
    const { data } = await axios.get(`/api/performance-overview/ending-soon-orders/${limit}/${offset}/${daterange}`);
    return data;
  } catch (error) {
    error.status === ERROR
      ? commit(CONSTANTS.SET_ENDING_ORDERS_ERROR, errorManyAdverts)
      : commit(CONSTANTS.SET_ENDING_ORDERS_ERROR, error?.message);
    return error;
  }
};

const getPacing = async ({ commit }, { limit, offset, daterange }): Promise<ImpressionsByClient[]> => {
  commit(CONSTANTS.SET_PACING_ERROR, null);
  try {
    const { data } = await axios.get(`/api/performance-overview/pacing-orders/${limit}/${offset}/${daterange}`);
    return data;
  } catch (error) {
    error.status === ERROR
      ? commit(CONSTANTS.SET_PACING_ERROR, errorManyAdverts)
      : commit(CONSTANTS.SET_PACING_ERROR, error?.message);
    return error;
  }
};

const getUsersByTenancy = async ({ commit }, { search, limit, agency }) => {
  commit(CONSTANTS.SET_FILTER_ERROR, null);
  let url = `/api/get-user-by-tenancy/${agency}/${limit}`;

  if (search && search !== '') {
    url += `/${encodeURIComponent(search)}`;
  }
  try {
    const { data } = await axios.get(url);
    if (!data?.error) {
      return data?.results;
    } else {
      commit(CONSTANTS.SET_FILTER_ERROR, data?.error?.message);
    }
  } catch (error) {
    commit(CONSTANTS.SET_FILTER_ERROR, error?.message);
  }
};

export default {
  getActiveCampaigns,
  getActiveClients,
  getActiveOrders,
  getImpressions,
  getImpressionsByClient,
  getImpressionsByTactic,
  getUsersByTenancy,
  getEndingSoonOrders,
  getPacing,
};
